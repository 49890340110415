/**
 * Create a wpm namespace under which all functions are declared
 */

// https://stackoverflow.com/a/5947280/4688612

(function (wpm, $, undefined) {

	const wpmDeduper = {
		keyName          : "_wpm_order_ids",
		cookieExpiresDays: 365,
	}

	const wpmRestSettings = {
		// cookiesAvailable                  : '_wpm_cookies_are_available',
		cookieWpmRestEndpointAvailable: "_wpm_endpoint_available",
		restEndpoint                  : "/wp-json/",
		restFails                     : 0,
		restFailsThreshold            : 10,
	}

	wpm.emailSelected         = false
	wpm.paymentMethodSelected = false

	// wpm.checkIfCookiesAvailable = function () {
	//
	//     // read the cookie if previously set, if it is return true, otherwise continue
	//     if (wpm.getCookie(wpmRestSettings.cookiesAvailable)) {
	//         return true;
	//     }
	//
	//     // set the cookie for the session
	//     Cookies.set(wpmRestSettings.cookiesAvailable, true);
	//
	//     // read cookie, true if ok, false if not ok
	//     return !!wpm.getCookie(wpmRestSettings.cookiesAvailable);
	// }

	wpm.useRestEndpoint = function () {

		// only if sessionStorage is available

		// only if REST API endpoint is generally accessible
		// check in sessionStorage if we checked before and return answer
		// otherwise check if the endpoint is available, save answer in sessionStorage and return answer

		// only if not too many REST API errors happened

		return wpm.isSessionStorageAvailable() &&
			wpm.isRestEndpointAvailable() &&
			wpm.isBelowRestErrorThreshold()
	}

	wpm.isBelowRestErrorThreshold = function () {
		return window.sessionStorage.getItem(wpmRestSettings.restFails) <= wpmRestSettings.restFailsThreshold
	}

	wpm.isRestEndpointAvailable = function () {

		if (window.sessionStorage.getItem(wpmRestSettings.cookieWpmRestEndpointAvailable)) {
			return JSON.parse(window.sessionStorage.getItem(wpmRestSettings.cookieWpmRestEndpointAvailable))
		} else {
			// return wpm.testEndpoint();
			// just set the value whenever possible in order not to wait or block the main thread
			wpm.testEndpoint()
		}
	}

	wpm.isSessionStorageAvailable = function () {

		return !!window.sessionStorage
	}

	wpm.testEndpoint = function (
		url        = location.protocol + "//" + location.host + wpmRestSettings.restEndpoint,
		cookieName = wpmRestSettings.cookieWpmRestEndpointAvailable,
	) {
		// console.log('testing endpoint');

		jQuery.ajax(url, {
			type   : "HEAD",
			timeout: 1000,
			// async: false,
			statusCode: {
				200: function (response) {
					// Cookies.set(cookieName, true);
					// console.log('endpoint works');
					window.sessionStorage.setItem(cookieName, JSON.stringify(true))
				},
				404: function (response) {
					// Cookies.set(cookieName, false);
					// console.log('endpoint doesn\'t work');
					window.sessionStorage.setItem(cookieName, JSON.stringify(false))
				},
				0  : function (response) {
					// Cookies.set(cookieName, false);
					// console.log('endpoint doesn\'t work');
					window.sessionStorage.setItem(cookieName, JSON.stringify(false))
				},
			},
		}).then(response => {
			// console.log('test done')
			// console.log('result: ' + JSON.parse(window.sessionStorage.getItem(cookieName)));
			// return JSON.parse(window.sessionStorage.getItem(cookieName));
		})
	}

	wpm.isWpmRestEndpointAvailable = function (cookieName = wpmRestSettings.cookieWpmRestEndpointAvailable) {

		return !!wpm.getCookie(cookieName)
	}

	wpm.writeOrderIdToStorage = function (orderId, expireDays = 365) {

		// save the order ID in the browser storage

		if (!window.Storage) {
			let expiresDate = new Date()
			expiresDate.setDate(expiresDate.getDate() + wpmDeduper.cookieExpiresDays)

			let ids = []
			if (checkCookie()) {
				ids = JSON.parse(wpm.getCookie(wpmDeduper.keyName))
			}

			if (!ids.includes(orderId)) {
				ids.push(orderId)
				document.cookie = wpmDeduper.keyName + "=" + JSON.stringify(ids) + ";expires=" + expiresDate.toUTCString()
			}

		} else {
			if (localStorage.getItem(wpmDeduper.keyName) === null) {
				let ids = []
				ids.push(orderId)
				window.localStorage.setItem(wpmDeduper.keyName, JSON.stringify(ids))

			} else {
				let ids = JSON.parse(localStorage.getItem(wpmDeduper.keyName))
				if (!ids.includes(orderId)) {
					ids.push(orderId)
					window.localStorage.setItem(wpmDeduper.keyName, JSON.stringify(ids))
				}
			}
		}

		if (typeof wpm.storeOrderIdOnServer === "function" && wpmDataLayer.orderDeduplication) {
			wpm.storeOrderIdOnServer(orderId)
		}
	}

	function checkCookie() {
		let key = wpm.getCookie(wpmDeduper.keyName)
		return key !== ""
	}

	wpm.isOrderIdStored = function (orderId) {

		if (wpmDataLayer.orderDeduplication) {

			if (!window.Storage) {

				if (checkCookie()) {
					let ids = JSON.parse(wpm.getCookie(wpmDeduper.keyName))
					return ids.includes(orderId)
				} else {
					return false
				}
			} else {
				if (localStorage.getItem(wpmDeduper.keyName) !== null) {
					let ids = JSON.parse(localStorage.getItem(wpmDeduper.keyName))
					return ids.includes(orderId)
				} else {
					return false
				}
			}
		} else {
			console.log("order duplication prevention: off")
			return false
		}
	}

	wpm.isEmail = function (email) {

		// https://emailregex.com/

		let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

		return regex.test(email)
	}

	wpm.removeProductFromCart = function (productId, quantityToRemove = null) {

		try {

			if (!productId) throw Error("Wasn't able to retrieve a productId")

			productId = wpm.getIdBasedOndVariationsOutputSetting(productId)

			if (!productId) throw Error("Wasn't able to retrieve a productId")

			let quantity

			if (quantityToRemove == null) {
				quantity = wpmDataLayer.cart[productId].quantity
			} else {
				quantity = quantityToRemove
			}

			if (wpmDataLayer.cart[productId]) {

				let product = wpm.getProductDetailsFormattedForEvent(productId, quantity)

				jQuery(document).trigger("wpmRemoveFromCart", product)

				if (quantityToRemove == null || wpmDataLayer.cart[productId].quantity === quantityToRemove) {

					delete wpmDataLayer.cart[productId]

					if (sessionStorage) sessionStorage.setItem("wpmDataLayerCart", JSON.stringify(wpmDataLayer.cart))
				} else {

					wpmDataLayer.cart[productId].quantity = wpmDataLayer.cart[productId].quantity - quantity

					if (sessionStorage) sessionStorage.setItem("wpmDataLayerCart", JSON.stringify(wpmDataLayer.cart))
				}
			}
		} catch (e) {
			console.error(e)
			// console.log('getting cart from back end');
			// wpm.getCartItemsFromBackend();
			// console.log('getting cart from back end done');
		}
	}

	wpm.getIdBasedOndVariationsOutputSetting = function (productId) {

		try {
			if (wpmDataLayer?.general?.variationsOutput) {

				return productId
			} else {
				if (wpmDataLayer.products[productId].isVariation) {

					return wpmDataLayer.products[productId].parentId
				} else {

					return productId
				}
			}
		} catch (e) {
			console.error(e)
		}
	}

	// add_to_cart
	wpm.addProductToCart = function (productId, quantity) {

		try {

			if (!productId) throw Error("Wasn't able to retrieve a productId")

			productId = wpm.getIdBasedOndVariationsOutputSetting(productId)

			if (!productId) throw Error("Wasn't able to retrieve a productId")

			if (wpmDataLayer?.products[productId]) {

				let product = wpm.getProductDetailsFormattedForEvent(productId, quantity)

				jQuery(document).trigger("wpmAddToCart", product)

				// add product to cart wpmDataLayer['cart']

				// if the product already exists in the object, only add the additional quantity
				// otherwise create that product object in the wpmDataLayer['cart']
				if (wpmDataLayer?.cart[productId]) {

					wpmDataLayer.cart[productId].quantity = wpmDataLayer.cart[productId].quantity + quantity
				} else {

					if (!("cart" in wpmDataLayer)) wpmDataLayer.cart = {}

					wpmDataLayer.cart[productId] = wpm.getProductDetailsFormattedForEvent(productId, quantity)
				}

				if (sessionStorage) sessionStorage.setItem("wpmDataLayerCart", JSON.stringify(wpmDataLayer.cart))
			}
		} catch (e) {
			console.error(e)

			// fallback if wpmDataLayer.cart and wpmDataLayer.products got out of sync in case cart caching has an issue
			wpm.getCartItemsFromBackend()
		}
	}

	wpm.getCartItems = function () {

		if (sessionStorage) {
			if (!sessionStorage.getItem("wpmDataLayerCart") || wpmDataLayer.shop.page_type === "order_received_page") {
				sessionStorage.setItem("wpmDataLayerCart", JSON.stringify({}))
			} else {
				wpm.saveCartObjectToDataLayer(JSON.parse(sessionStorage.getItem("wpmDataLayerCart")))
			}
		} else {
			wpm.getCartItemsFromBackend()
		}
	}

	// get all cart items from the backend
	wpm.getCartItemsFromBackend = function () {
		try {
			let data = {
				action: "wpm_get_cart_items",
			}

			jQuery.ajax(
				{
					type    : "get",
					dataType: "json",
					// url     : ajax_object.ajax_url,
					url    : wpm.ajax_url,
					data   : data,
					success: function (cartItems) {

						// save all cart items into wpmDataLayer

						if (!cartItems["cart"]) cartItems["cart"] = {}

						wpm.saveCartObjectToDataLayer(cartItems["cart"])

						if (sessionStorage) sessionStorage.setItem("wpmDataLayerCart", JSON.stringify(cartItems["cart"]))
					},
				})
		} catch (e) {
			console.error(e)
		}
	}

	// get productIds from the backend
	wpm.getProductsFromBackend = function (productIds) {


		// reduce productIds by products already in the dataLayer
		productIds = productIds.filter(item => !wpmDataLayer.products.hasOwnProperty(item))

		// if no products IDs are in the object, don't try to get anything from the server
		if (!productIds || productIds.length === 0) return

		try {
			let data = {
				action    : "wpm_get_product_ids",
				productIds: productIds,
			}

			jQuery.ajax(
				{
					type    : "get",
					dataType: "json",
					// url     : ajax_object.ajax_url,
					url    : wpm.ajax_url,
					data   : data,
					success: function (products) {

						// merge products into wpmDataLayer.products
						wpmDataLayer.products = Object.assign({}, wpmDataLayer.products, products)
					},
					error  : function (response) {
						console.log(response)
					},
				})
		} catch (e) {
			console.error(e)
		}
	}

	wpm.saveCartObjectToDataLayer = function (cartObject) {

		wpmDataLayer.cart     = cartObject
		wpmDataLayer.products = Object.assign({}, wpmDataLayer.products, cartObject)
	}

	wpm.fireCheckoutOption = function (step, checkout_option = null, value = null) {

		let data = {
			step           : step,
			checkout_option: checkout_option,
			value          : value,
		}

		jQuery(document).trigger("wpmFireCheckoutOption", data)
	}

	wpm.fireCheckoutProgress = function (step) {

		let data = {
			step: step,
		}

		jQuery(document).trigger("wpmFireCheckoutProgress", data)
	}

	wpm.getPostIdFromString = function (string) {

		try {
			return string.match(/(post-)(\d+)/)[2]
		} catch (e) {
			console.error(e)
		}
	}

	wpm.triggerViewItemList = function (productId) {

		if (!productId) throw Error("Wasn't able to retrieve a productId")

		productId = wpm.getIdBasedOndVariationsOutputSetting(productId)

		if (!productId) throw Error("Wasn't able to retrieve a productId")

		jQuery(document).trigger("wpmViewItemList", wpm.getProductDataForViewItemEvent(productId))
	}

	wpm.getProductDataForViewItemEvent = function (productId) {

		if (!productId) throw Error("Wasn't able to retrieve a productId")

		try {
			if (wpmDataLayer.products[productId]) {

				return wpm.getProductDetailsFormattedForEvent(productId)
			}
		} catch (e) {
			console.error(e)
		}
	}

	wpm.getMainProductIdFromProductPage = function () {

		try {
			if (["simple", "variable", "grouped", "composite", "bundle"].indexOf(wpmDataLayer.shop.product_type) >= 0) {
				return jQuery(".wpmProductId:first").data("id")
			} else {
				return false
			}
		} catch (e) {
			console.error(e)
		}
	}

	wpm.viewItemListTriggerTestMode = function (target) {

		jQuery(target).css({"position": "relative"})
		jQuery(target).append("<div id=\"viewItemListTriggerOverlay\"></div>")
		jQuery(target).find("#viewItemListTriggerOverlay").css({
			"z-index"         : "10",
			"display"         : "block",
			"position"        : "absolute",
			"height"          : "100%",
			"top"             : "0",
			"left"            : "0",
			"right"           : "0",
			"opacity"         : wpmDataLayer.viewItemListTrigger.opacity,
			"background-color": wpmDataLayer.viewItemListTrigger.backgroundColor,
		})
	}

	wpm.getSearchTermFromUrl = function () {

		try {
			let urlParameters = new URLSearchParams(window.location.search)
			return urlParameters.get("s")
		} catch (e) {
			console.error(e)
		}
	}

	// we need this to track timeouts for intersection observers
	let ioTimeouts = {}

	wpm.observerCallback = function (entries, observer) {

		entries.forEach((entry) => {

			try {
				let productId

				let elementId = jQuery(entry.target).data("ioid")

				// Get the productId from next element, if wpmProductId is a sibling, like in Gutenberg blocks
				// otherwise go search in children, like in regular WC loop items
				if (jQuery(entry.target).next(".wpmProductId").length) {
					// console.log('test 1');
					productId = jQuery(entry.target).next(".wpmProductId").data("id")
				} else {
					productId = jQuery(entry.target).find(".wpmProductId").data("id")
				}


				if (!productId) throw Error("wpmProductId element not found")

				if (entry.isIntersecting) {

					ioTimeouts[elementId] = setTimeout(() => {

						wpm.triggerViewItemList(productId)
						if (wpmDataLayer.viewItemListTrigger.testMode) wpm.viewItemListTriggerTestMode(entry.target)
						if (wpmDataLayer.viewItemListTrigger.repeat === false) observer.unobserve(entry.target)
					}, wpmDataLayer.viewItemListTrigger.timeout)

				} else {

					clearTimeout(ioTimeouts[elementId])
					if (wpmDataLayer.viewItemListTrigger.testMode) jQuery(entry.target).find("#viewItemListTriggerOverlay").remove()
				}
			} catch (e) {
				console.error(e)
			}
		})
	}

	// fire view_item_list only on products that have become visible
	let io
	let ioid = 0
	let allIoElementsToWatch

	let getAllElementsToWatch = function () {

		allIoElementsToWatch = jQuery(".wpmProductId")
			.map(function (i, elem) {

				if (
					jQuery(elem).parent().hasClass("type-product") ||
					jQuery(elem).parent().hasClass("product") ||
					jQuery(elem).parent().hasClass("product-item-inner")
				) {
					return jQuery(elem).parent()
				} else if (
					jQuery(elem).prev().hasClass("wc-block-grid__product") ||
					jQuery(elem).prev().hasClass("product") ||
					jQuery(elem).prev().hasClass("product-small") ||
					jQuery(elem).prev().hasClass("woocommerce-LoopProduct-link")
				) {
					return jQuery(this).prev()
				} else if (jQuery(elem).closest(".product").length) {
					return jQuery(elem).closest(".product")
				}
			})
	}

	wpm.startIntersectionObserverToWatch = function () {

		try {
			// enable view_item_list test mode from browser
			if (wpm.urlHasParameter("vildemomode")) wpmDataLayer.viewItemListTrigger.testMode = true

			// set up intersection observer
			io = new IntersectionObserver(wpm.observerCallback, {
				threshold: wpmDataLayer.viewItemListTrigger.threshold,
			})

			getAllElementsToWatch()

			allIoElementsToWatch.each(function (i, elem) {

				jQuery(elem[0]).data("ioid", ioid++)

				io.observe(elem[0])
			})
		} catch (e) {
			console.error(e)
		}
	}

	// watch DOM for new lazy loaded products and add them to the intersection observer
	wpm.startProductsMutationObserverToWatch = function () {

		try {
			// Pass in the target node, as well as the observer options

			// selects the most common parent node
			// https://stackoverflow.com/a/7648323/4688612
			let productsNode = jQuery(".wpmProductId:eq(0)").parents().has(jQuery(".wpmProductId:eq(1)").parents()).first()

			if (productsNode.length) {
				productsMutationObserver.observe(productsNode[0], {
					attributes   : true,
					childList    : true,
					characterData: true,
				})
			}
		} catch (e) {
			console.error(e)
		}
	}

	// Create an observer instance
	let productsMutationObserver = new MutationObserver(function (mutations) {

		mutations.forEach(function (mutation) {
			let newNodes = mutation.addedNodes // DOM NodeList
			if (newNodes !== null) { // If there are new nodes added
				let nodes = jQuery(newNodes) // jQuery set
				nodes.each(function () {
					if (
						jQuery(this).hasClass("type-product") ||
						jQuery(this).hasClass("product-small") ||
						jQuery(this).hasClass("wc-block-grid__product")
					) {
						// check if the node has a child or sibling wpmProductId
						// if yes add it to the intersectionObserver
						if (hasWpmProductIdElement(this)) {
							jQuery(this).data("ioid", ioid++)
							io.observe(this)
						}
					}
				})
			}
		})
	})

	let hasWpmProductIdElement = function (elem) {
		return !!(jQuery(elem).find(".wpmProductId").length ||
			jQuery(elem).siblings(".wpmProductId").length)
	}

	wpm.setCookie = function (cookieName, cookieValue = "", expiryDays = null) {

		if (expiryDays) {

			let d = new Date()
			d.setTime(d.getTime() + (expiryDays * 24 * 60 * 60 * 1000))
			let expires     = "expires=" + d.toUTCString()
			document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/"
		} else {
			document.cookie = cookieName + "=" + cookieValue + ";path=/"
		}
	}

	wpm.getCookie = function (cookieName) {

		let name          = cookieName + "="
		let decodedCookie = decodeURIComponent(document.cookie)
		let ca            = decodedCookie.split(";")

		for (let i = 0; i < ca.length; i++) {

			let c = ca[i]

			while (c.charAt(0) == " ") {
				c = c.substring(1)
			}

			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length)
			}
		}

		return ""
	}

	wpm.getWpmSessionData = function () {

		if (window.sessionStorage) {

			let data = window.sessionStorage.getItem("_wpm")

			if (data !== null) {
				return JSON.parse(data)
			} else {
				return {}
			}
		} else {
			return {}
		}
	}

	wpm.setWpmSessionData = function (data) {
		if (window.sessionStorage) {
			window.sessionStorage.setItem("_wpm", JSON.stringify(data))
		}
	}

	wpm.storeOrderIdOnServer = function (orderId) {

		try {
			// save the state in the database
			let data = {
				action  : "wpm_purchase_pixels_fired",
				order_id: orderId,
				// nonce   : ajax_object.nonce,
				nonce: wpm.nonce,
			}

			jQuery.ajax(
				{
					type    : "post",
					dataType: "json",
					// url     : ajax_object.ajax_url,
					url    : wpm.ajax_url,
					data   : data,
					success: function (response) {
						if (response.success === false) {
							console.log(response)
						}
					},
					error  : function (response) {
						console.log(response)
					},
				})
		} catch (e) {
			console.error(e)
		}
	}

	wpm.getProductIdByCartItemKeyUrl = function (url) {

		let searchParams = new URLSearchParams(url.search)
		let cartItemKey  = searchParams.get("remove_item")

		let productId

		if (wpmDataLayer.cartItemKeys[cartItemKey]["variation_id"] === 0) {
			productId = wpmDataLayer.cartItemKeys[cartItemKey]["product_id"]
		} else {
			productId = wpmDataLayer.cartItemKeys[cartItemKey]["variation_id"]
		}

		return productId
	}

	wpm.getAddToCartLinkProductIds = function () {

		return jQuery("a").map(function () {
			let href = jQuery(this).attr("href")

			if (href && href.includes("?add-to-cart=")) {
				let matches = href.match(/(add-to-cart=)(\d+)/)
				if (matches) return matches[2]
			}
		}).get()
	}

	wpm.getProductDetailsFormattedForEvent = function (productId, quantity = 1) {

		let product = {
			id           : productId.toString(),
			dyn_r_ids    : wpmDataLayer.products[productId].dyn_r_ids,
			name         : wpmDataLayer.products[productId].name,
			list_name    : wpmDataLayer.shop.list_name,
			brand        : wpmDataLayer.products[productId].brand,
			category     : wpmDataLayer.products[productId].category,
			variant      : wpmDataLayer.products[productId].variant,
			list_position: wpmDataLayer.products[productId].position,
			quantity     : quantity,
			price        : wpmDataLayer.products[productId].price,
			currency     : wpmDataLayer.shop.currency,
			isVariable   : wpmDataLayer.products[productId].isVariable,
			isVariation  : wpmDataLayer.products[productId].isVariation,
			parentId     : wpmDataLayer.products[productId].parentId,
		}

		if (product.isVariation) product["parentId_dyn_r_ids"] = wpmDataLayer.products[productId].parentId_dyn_r_ids

		return product
	}

	wpm.setReferrerToCookie = function () {

		// can't use session storage as we can't read it from the server
		if (!wpm.getCookie("wpmReferrer")) {
			wpm.setCookie("wpmReferrer", document.referrer)
		}
	}

	wpm.getReferrerFromCookie = function () {

		if (wpm.getCookie("wpmReferrer")) {
			return wpm.getCookie("wpmReferrer")
		} else {
			return null
		}
	}

	wpm.getClidFromBrowser = function (clidId = "gclid") {

		let clidCookieId

		clidCookieId = {
			gclid: "_gcl_aw",
			dclid: "_gcl_dc",
		}

		if (wpm.getCookie(clidCookieId[clidId])) {

			let clidCookie = wpm.getCookie(clidCookieId[clidId])
			let matches    = clidCookie.match(/(GCL.[\d]*.)(.*)/)
			return matches[2]
		} else {
			return ""
		}
	}

	wpm.getUserAgent = function () {
		return navigator.userAgent
	}

	wpm.getViewPort = function () {
		return {
			width : Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
			height: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
		}
	}

	/**
	 * Handle Cookie Management Platforms
	 */

	let getComplianzCookies = function () {

		let cmplz_statistics     = wpm.getCookie("cmplz_statistics")
		let cmplz_marketing      = wpm.getCookie("cmplz_marketing")
		let cmplz_consent_status = wpm.getCookie("cmplz_consent_status") || wpm.getCookie("cmplz_banner-status")

		if (cmplz_consent_status) {
			return {
				analytics       : cmplz_statistics === "allow",
				ads             : cmplz_marketing === "allow",
				visitorHasChosen: true,
			}
		} else {
			return false
		}
	}

	let getCookieLawInfoCookies = function () {

		let analyticsCookie  = wpm.getCookie("cookielawinfo-checkbox-analytics") || wpm.getCookie("cookielawinfo-checkbox-analytiques")
		let adsCookie        = wpm.getCookie("cookielawinfo-checkbox-advertisement") || wpm.getCookie("cookielawinfo-checkbox-publicite")
		let visitorHasChosen = wpm.getCookie("CookieLawInfoConsent")

		if (analyticsCookie || adsCookie) {

			return {
				analytics       : analyticsCookie === "yes",
				ads             : adsCookie === "yes",
				visitorHasChosen: !!visitorHasChosen,
			}
		} else {
			return false
		}
	}


	let
		wpmConsentValues              = {}
	wpmConsentValues.categories       = {}
	wpmConsentValues.pixels           = []
	wpmConsentValues.mode             = "category"
	wpmConsentValues.visitorHasChosen = false

	wpm.getConsentValues = function () {
		return wpmConsentValues
	}

	wpm.setConsentValueCategories = function (analytics = false, ads = false) {
		wpmConsentValues.categories.analytics = analytics
		wpmConsentValues.categories.ads       = ads
	}

	wpm.updateConsentCookieValues = function (explicitConsent = false) {

		// ad_storage
		// analytics_storage
		// functionality_storage
		// personalization_storage
		// security_storage

		let cookie

		if (cookie = wpm.getCookie("CookieConsent")) {

			// Cookiebot
			// https://wordpress.org/plugins/cookiebot/
			cookie = decodeURI(cookie)

			wpmConsentValues.categories.analytics = cookie.indexOf("statistics:true") >= 0
			wpmConsentValues.categories.ads       = cookie.indexOf("marketing:true") >= 0
			wpmConsentValues.visitorHasChosen     = true

		} else if (cookie = wpm.getCookie("CookieScriptConsent")) {

			// Cookie Script
			// https://wordpress.org/plugins/cookie-script-com/

			cookie = JSON.parse(cookie)

			if (cookie.action === "reject") {
				wpmConsentValues.categories.analytics = false
				wpmConsentValues.categories.ads       = false
			} else if (cookie.categories.length === 2) {
				wpmConsentValues.categories.analytics = true
				wpmConsentValues.categories.ads       = true
			} else {
				wpmConsentValues.categories.analytics = cookie.categories.indexOf("performance") >= 0
				wpmConsentValues.categories.ads       = cookie.categories.indexOf("targeting") >= 0
			}

			wpmConsentValues.visitorHasChosen = true

		} else if (cookie = wpm.getCookie("borlabs-cookie")) {

			// Borlabs Cookie
			// https://borlabs.io/borlabs-cookie/

			cookie = decodeURI(cookie)
			cookie = JSON.parse(cookie)

			wpmConsentValues.categories.analytics = !!cookie?.consents?.statistics
			wpmConsentValues.categories.ads       = !!cookie?.consents?.marketing
			wpmConsentValues.visitorHasChosen     = true
			wpmConsentValues.pixels               = [...cookie?.consents?.statistics || [], ...cookie?.consents?.marketing || []]
			wpmConsentValues.mode                 = "pixel"

		} else if (cookie = getComplianzCookies()) {

			// Complianz Cookie
			// https://wordpress.org/plugins/complianz-gdpr/

			wpmConsentValues.categories.analytics = cookie.analytics === true
			wpmConsentValues.categories.ads       = cookie.ads === true
			wpmConsentValues.visitorHasChosen     = cookie.visitorHasChosen

		} else if (cookie = wpm.getCookie("cookie_notice_accepted")) {

			// Cookie Compliance (free version)
			// https://wordpress.org/plugins/cookie-notice/

			wpmConsentValues.categories.analytics = true
			wpmConsentValues.categories.ads       = true
			wpmConsentValues.visitorHasChosen     = true

		} else if (cookie = wpm.getCookie("hu-consent")) {

			// Cookie Compliance (pro version)
			// https://wordpress.org/plugins/cookie-notice/

			cookie = JSON.parse(cookie)

			wpmConsentValues.categories.analytics = !!cookie.categories["3"]
			wpmConsentValues.categories.ads       = !!cookie.categories["4"]
			wpmConsentValues.visitorHasChosen     = true

		} else if (cookie = getCookieLawInfoCookies()) {

			// CookieYes, GDPR Cookie Consent (Cookie Law Info)
			// https://wordpress.org/plugins/cookie-law-info/

			wpmConsentValues.categories.analytics = cookie.analytics === true
			wpmConsentValues.categories.ads       = cookie.ads === true
			wpmConsentValues.visitorHasChosen     = cookie.visitorHasChosen === true

		} else if (cookie = wpm.getCookie("moove_gdpr_popup")) {

			// GDPR Cookie Compliance Plugin by Moove Agency
			// https://wordpress.org/plugins/gdpr-cookie-compliance/
			// TODO write documentation on how to set up the plugin in order for this to work properly

			cookie = JSON.parse(cookie)

			wpmConsentValues.categories.analytics = cookie.thirdparty === "1"
			wpmConsentValues.categories.ads       = cookie.advanced === "1"
			wpmConsentValues.visitorHasChosen     = true

		} else {
			// consentValues.categories.analytics = true
			// consentValues.categories.ads       = true

			wpmConsentValues.categories.analytics = !explicitConsent
			wpmConsentValues.categories.ads       = !explicitConsent
		}
	}

	wpm.updateConsentCookieValues()

	wpm.setConsentDefaultValuesToExplicit = function () {
		wpmConsentValues.categories = {
			analytics: false,
			ads      : false,
		}
	}

	wpm.canIFire = function (category, pixelName) {

		let canIFireMode

		if ("category" === wpmConsentValues.mode) {
			canIFireMode = !!wpmConsentValues.categories[category]
		} else if ("pixel" === wpmConsentValues.mode) {
			canIFireMode = wpmConsentValues.pixels.includes(pixelName)

			// If a user sets "bing-ads" in Borlabs Cookie instead of
			// "microsoft-ads" in the Borlabs settings, we need to check
			// for that too.
			if (false === canIFireMode && "microsoft-ads" === pixelName) {
				canIFireMode = wpmConsentValues.pixels.includes("bing-ads")
			}
		} else {
			console.error("Couldn't find a valid consent mode in wpmConsentValues")
			canIFireMode = false
		}

		if (canIFireMode) {
			return true
		} else {
			if (true || wpm.urlHasParameter("debugConsentMode")) {
				wpm.logPreventedPixelLoading(pixelName, category)
			}

			return false
		}
	}

	wpm.logPreventedPixelLoading = function (pixelName, category) {

		if (wpmDataLayer?.shop?.cookie_consent_mgmt?.explicit_consent) {
			console.log("WooCommerce Pixel Manager: The \"" + pixelName + " (category: " + category + ")\" pixel has not fired because you have not given consent for it yet. (WPM is in explicit consent mode.)")
		} else {
			console.log("WooCommerce Pixel Manager: The \"" + pixelName + " (category: " + category + ")\" pixel has not fired because you have removed consent for this pixel. (WPM is in implicit consent mode.)")
		}
	}

	/**
	 * Runs through each script in <head> and blocks / unblocks it according to the plugin settings
	 * and user consent.
	 */

	// https://stackoverflow.com/q/65453565/4688612
	wpm.scriptTagObserver = new MutationObserver((mutations) => {
		mutations.forEach(({addedNodes}) => {
			[...addedNodes]
				.forEach(node => {

					if ($(node).data("wpm-cookie-category")) {

						// If the pixel category has been approved > unblock
						// If the pixel belongs to more than one category, then unblock if one of the categories has been approved
						// If no category has been approved, but the Google Consent Mode is active, then only unblock the Google scripts

						if (wpm.shouldScriptBeActive(node)) {
							wpm.unblockScript(node)
						} else {
							wpm.blockScript(node)
						}
					}
				})
		})
	})

	wpm.scriptTagObserver.observe(document.head, {childList: true, subtree: true})
	window.addEventListener("DOMContentLoaded", () => wpm.scriptTagObserver.disconnect())

	wpm.shouldScriptBeActive = function (node) {

		if (
			wpmDataLayer.shop.cookie_consent_mgmt.explicit_consent ||
			wpmConsentValues.visitorHasChosen
		) {

			if (wpmConsentValues.mode === "category" && $(node).data("wpm-cookie-category").split(",").some(element => wpmConsentValues.categories[element])) {
				return true
			} else if (wpmConsentValues.mode === "pixel" && wpmConsentValues.pixels.includes($(node).data("wpm-pixel-name"))) {
				return true
			} else if (wpmConsentValues.mode === "pixel" && $(node).data("wpm-pixel-name") === "google" && ["google-analytics", "google-ads"].some(element => wpmConsentValues.pixels.includes(element))) {
				return true
			} else if (wpmDataLayer?.pixels?.google?.consent_mode?.active && $(node).data("wpm-pixel-name") === "google") {
				return true
			} else {
				return false
			}
		} else {
			return true
		}
	}


	wpm.unblockScript = function (scriptNode, removeAttach = false) {

		if (removeAttach) $(scriptNode).remove()

		let wpmSrc = $(scriptNode).data("wpm-src")
		if (wpmSrc) $(scriptNode).attr("src", wpmSrc)

		scriptNode.type = "text/javascript"

		if (removeAttach) $(scriptNode).appendTo("head")

		jQuery(document).trigger("wpmPreLoadPixels", {})
	}

	wpm.blockScript = function (scriptNode, removeAttach = false) {

		if (removeAttach) $(scriptNode).remove()

		if ($(scriptNode).attr("src")) $(scriptNode).removeAttr("src")
		scriptNode.type = "blocked/javascript"

		if (removeAttach) $(scriptNode).appendTo("head")
	}

	wpm.unblockAllScripts = function (analytics = true, ads = true) {
		// console.log('unblocking all scripts')

		$.each(
			$("script[type=\"blocked/javascript\"]"), function (index, scriptNode) {

				if ($(scriptNode).data("wpm-cookie-category").includes("analytics") && analytics) {
					wpm.unblockScript(scriptNode, true)
				} else if ($(scriptNode).data("wpm-cookie-category").includes("ads") && ads) {
					wpm.unblockScript(scriptNode, true)
				}
			})

		jQuery(document).trigger("wpmPreLoadPixels", {})
	}

	wpm.unblockSelectedPixels = function () {
		$.each(
			$("script[type=\"blocked/javascript\"]"), function (index, node) {

				if (wpmConsentValues.pixels.includes($(node).data("wpm-pixel-name"))) {
					wpm.unblockScript(node, true)
				} else if ($(node).data("wpm-pixel-name") === "google" && ["google-analytics", "google-ads"].some(element => wpmConsentValues.pixels.includes(element))) {
					wpm.unblockScript(node, true)
				}
			})

		jQuery(document).trigger("wpmPreLoadPixels", {})
	}


	/**
	 * Block or unblock scripts for each CMP immediately after cookie consent has been updated
	 * by the visitor.
	 */

	// Borlabs Cookie
	// If visitor accepts cookies in Borlabs Cookie unblock the scripts
	window.addEventListener("borlabs-cookie-consent-saved", function () {

		wpm.updateConsentCookieValues()

		if (wpmConsentValues.mode === "pixel") {

			wpm.unblockSelectedPixels()
			wpm.updateGoogleConsentMode(wpmConsentValues.pixels.includes("google-analytics"), wpmConsentValues.pixels.includes("google-ads"))
		} else {

			wpm.unblockAllScripts(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
			wpm.updateGoogleConsentMode(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
		}
	})

	// Cookiebot
	// If visitor accepts cookies in Cookiebot unblock the scripts
	// https://www.cookiebot.com/en/developer/
	window.addEventListener("CookiebotOnAccept", function () {

		if (Cookiebot.consent.statistics) wpmConsentValues.categories.analytics = true
		if (Cookiebot.consent.marketing) wpmConsentValues.categories.ads = true

		wpm.unblockAllScripts(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
		wpm.updateGoogleConsentMode(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)

	}, false)

	/**
	 * Cookie Script
	 * If visitor accepts cookies in Cookie Script unblock the scripts
	 * https://support.cookie-script.com/article/20-custom-events
	 */
	window.addEventListener("CookieScriptAccept", function (e) {

		if (e.detail.categories.includes("performance")) wpmConsentValues.categories.analytics = true
		if (e.detail.categories.includes("targeting")) wpmConsentValues.categories.ads = true

		wpm.unblockAllScripts(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
		wpm.updateGoogleConsentMode(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
	})

	/**
	 * Cookie Script
	 * If visitor accepts cookies in Cookie Script unblock the scripts
	 * https://support.cookie-script.com/
	 */
	window.addEventListener("CookieScriptAcceptAll", function () {

		wpm.unblockAllScripts(true, true)
		wpm.updateGoogleConsentMode(true, true)
	})

	// Complianz Cookie
	// If visitor accepts cookies in Complianz unblock the scripts
	document.addEventListener("cmplzStatusChange", function () {

		wpm.updateConsentCookieValues()

		wpm.unblockAllScripts(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
		wpm.updateGoogleConsentMode(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
	})

	// Cookie Compliance by hu-manity.co (free and pro)
	// If visitor accepts cookies in Cookie Notice by hu-manity.co unblock the scripts (free version)
	// https://wordpress.org/support/topic/events-on-consent-change/#post-15202792
	document.addEventListener("setCookieNotice", function () {

		wpm.updateConsentCookieValues()

		wpm.unblockAllScripts(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
		wpm.updateGoogleConsentMode(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
	})

	/**
	 * Cookie Compliance by hu-manity.co (free and pro)
	 * If visitor accepts cookies in Cookie Notice by hu-manity.co unblock the scripts (pro version)
	 * https://wordpress.org/support/topic/events-on-consent-change/#post-15202792
	 * Because Cookie Notice has no documented API or event that is being triggered on consent save or update
	 * we have to solve this by using a mutation observer.
	 *
	 * @type {MutationObserver}
	 */

	wpm.huObserver = new MutationObserver(function (mutations) {
		mutations.forEach(({addedNodes}) => {
			[...addedNodes]
				.forEach(node => {

					if (node.id === "hu") {

						jQuery(".hu-cookies-save").on("click", function () {
							wpm.updateConsentCookieValues()
							wpm.unblockAllScripts(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
							wpm.updateGoogleConsentMode(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
						})
					}
				})
		})
	})

	if (window.hu) {
		wpm.huObserver.observe(document.documentElement || document.body, {childList: true, subtree: true})
	}

	wpm.version = function () {
		console.log(wpmDataLayer.version)
	}

	// https://api.jquery.com/jquery.getscript/
	wpm.loadScriptAndCacheIt = function (url, options) {

		// Allow user to set any option except for dataType, cache, and url
		options = jQuery.extend(options || {}, {
			dataType: "script",
			cache   : true,
			url     : url,
		})

		// Use $.ajax() since it is more flexible than $.getScript
		// Return the jqXHR object so we can chain callbacks
		return jQuery.ajax(options)
	}

	wpm.getOrderItemPrice = function (orderItem) {
		return (orderItem.total + orderItem.total_tax) / orderItem.quantity
	}

	wpm.hasLoginEventFired = function () {
		let data = wpm.getWpmSessionData()
		return data?.loginEventFired
	}

	wpm.setLoginEventFired = function () {
		let data                = wpm.getWpmSessionData()
		data["loginEventFired"] = true
		wpm.setWpmSessionData(data)
	}

	wpm.wpmDataLayerExists = function () {
		return new Promise(function (resolve) {
			(function waitForVar() {
				if (typeof wpmDataLayer !== "undefined") return resolve()
				setTimeout(waitForVar, 50)
			})()
		})
	}

	wpm.jQueryExists = function () {
		return new Promise(function (resolve) {
			(function waitForjQuery() {
				if (typeof jQuery !== "undefined") return resolve()
				setTimeout(waitForjQuery, 100)
			})()
		})
	}

	wpm.pageLoaded = function () {
		return new Promise(function (resolve) {
			(function waitForVar() {
				if ("complete" === document.readyState) return resolve()
				setTimeout(waitForVar, 50)
			})()
		})
	}

	wpm.pageReady = function () {
		return new Promise(function (resolve) {
			(function waitForVar() {
				if ("interactive" === document.readyState || "complete" === document.readyState) return resolve()
				setTimeout(waitForVar, 50)
			})()
		})
	}

	wpm.isMiniCartActive = function () {
		if (window.sessionStorage) {
			for (const [key, value] of Object.entries(window.sessionStorage)) {
				if (key.includes("wc_fragments")) {
					return true
				}
			}
			return false
		} else {
			return false
		}
	}

	wpm.doesWooCommerceCartExist = function () {
		return document.cookie.includes("woocommerce_items_in_cart")
	}

	wpm.urlHasParameter = function (parameter) {
		let urlParams = new URLSearchParams(window.location.search)
		return urlParams.has(parameter)
	}

}(window.wpm = window.wpm || {}, jQuery))
